.slideshow-wrapper {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.slideshow-items {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  will-change: transform;
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000; }
  @media (min-width: 620px) {
    .slideshow-items {
      width: 45%;
      margin-left: 28%; } }

.slideshow-item {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 100%;
  height: 100%;
  padding: 0 1.5rem;
  transition: opacity 0.2s ease-in-out, transform 0.35s ease-in-out;
  transform: scale(0.9);
  will-change: opacity, transform; }

.clip-splash--pagination-button {
  z-index: 5;
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  border: none;
  background: rgba(255, 255, 255, 0);
  transition: opacity 0.15s ease-in-out;
  opacity: 0;
  text-indent: -9999rem;
  animation: fadebg 0.7s ease-out 1;
  animation-delay: 10s;
  border-radius: 15px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent; }
  .clip-splash--pagination-button:focus {
    outline: none; }
  .clip-splash--pagination-button:focus:hover {
    opacity: 0; }

@keyframes fadebg {
  0% {
    opacity: 0; }
  30% {
    opacity: 0.8; }
  50% {
    opacity: 0.3; }
  70% {
    opacity: 0.8; }
  0% {
    opacity: 0; } }

.clip-splash--next-button {
  composes: clip-splash--pagination-button;
  right: 1.5rem;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0.1) 80%); }
  .clip-splash--next-button:hover {
    cursor: pointer;
    opacity: 1; }
  @media (min-width: 620px) {
    .clip-splash--next-button {
      max-width: none;
      right: 1.5rem;
      width: 30%; } }

.clip-splash--previous-button {
  left: 1.5rem;
  composes: clip-splash--pagination-button;
  background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0.1) 80%); }
  .clip-splash--previous-button:hover {
    cursor: pointer;
    opacity: 1; }
  @media (min-width: 620px) {
    .clip-splash--previous-button {
      width: 30%; } }
