.layout-wrapper {
  position: relative;
  width: 100%;
  height: 100%; }
  @media (min-width: 620px) {
    .layout-wrapper {
      padding: 2rem; } }
  @media (dight: 700px) {
    .layout-wrapper {
      padding: 0rem; } }

.layout-inner {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%; }

.layout-content {
  background-color: #121314;
  color: white;
  overflow: auto;
  height: 100%;
  opacity: 0;
  animation: layoutAppearence 0.5s ease-in 1;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  will-change: opacity, transform; }
  @media (min-width: 620px) {
    .layout-content {
      position: absolute;
      width: 100%;
      top: 50%;
      left: 50%;
      max-width: 1000px;
      max-height: 790px;
      border-radius: 20px;
      transform: translate(-50%, -50%);
      box-shadow: 0px 20px 30px 20px rgba(0, 0, 0, 0.2); } }
  @media (max-height: 700px) {
    .layout-content {
      border-radius: 0px; } }

@keyframes layoutAppearence {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
