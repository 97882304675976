body {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  background-color: #030303; }
  @media (min-width: 620px) {
    body {
      background-color: #333; } }

body,
html {
  height: 100%;
  width: 100%; }

h1,
h2,
h3,
h4,
h5,
p {
  letter-spacing: 0.01rem;
  line-height: 1.5rem; }

a {
  color: #6e33f1;
  text-decoration: none;
  transition: color 0.15s ease-in-out; }
  a:hover {
    color: #888; }

i {
  font-style: italic; }

:global #app_root {
  position: relative;
  width: 100%;
  height: 100%; }
