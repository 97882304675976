.clip-splash--wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  max-width: 380px;
  margin: 0 auto; }
  @media (min-width: 620px) {
    .clip-splash--wrapper {
      max-width: none; } }
  @media (max-height: 700px) {
    .clip-splash--wrapper {
      max-width: 280px; } }

.clip-splash--content {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 8px; }

.clip-splash--content-inner {
  z-index: 3;
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem 0 1rem;
  width: 100%;
  height: 100%; }
  @media (max-height: 700px) {
    .clip-splash--content-inner {
      padding-top: 1rem; } }

.clip-splash--content--item {
  flex: 0 1 auto; }

.clip-splash--background {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 8px; }

.clip-splash--background-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px; }
  .clip-splash--background-inner:after {
    content: ' ';
    z-index: 5;
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, #121314 -2%, rgba(0, 0, 0, 0) 100%); }
  .clip-splash--background-inner > img {
    position: absolute;
    width: 200%;
    height: 130%;
    opacity: 0.5;
    filter: blur(5px) brightness(1.1) contrast(1.2);
    transform: translate(-25%, -8%) scale(1.1);
    will-change: transform; }

.clip-splash--background-inactive {
  composes: clip-splash--background;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) -5%, #222222 100%); }

.clip-splash--content--item--image-wrapper {
  composes: clip-splash--content--item;
  position: relative;
  background: #252525;
  border-radius: 15px;
  width: 80%;
  padding-top: 80%; }
  @media (max-height: 700px) and (min-width: 500px) {
    .clip-splash--content--item--image-wrapper {
      width: 60%;
      padding-top: 60%; } }
  .clip-splash--content--item--image-wrapper img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 15px; }

.clip-splash--content--item--info {
  text-align: center;
  padding: 1.2rem 0.25rem 0.5rem;
  min-height: 6rem; }
  @media (min-width: 620px) {
    .clip-splash--content--item--info {
      padding: 2rem 1rem 0.5rem; } }
  .clip-splash--content--item--info h2 {
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0.05rem;
    padding-bottom: 0.5rem; }
  .clip-splash--content--item--info p {
    font-size: 0.9rem;
    line-height: 1.3rem;
    letter-spacing: 0.02rem; }

.clip-splash--audio-waves--wrapper {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20%; }

.clip-splash--audio-waves--items {
  overflow: hidden;
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0;
  justify-content: space-between;
  animation: appear 0.6s ease-in-out 1;
  animation-delay: 1s;
  animation-fill-mode: forwards; }

@keyframes appear {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.clip-splash--audio-waves--item {
  width: 13px;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px 15px 0 0;
  margin-right: 0.5rem;
  transform: translateY(85%); }
  .clip-splash--audio-waves--item:first-child {
    margin-left: 0rem; }
  .clip-splash--audio-waves--item:last-child {
    margin-right: 0rem; }
