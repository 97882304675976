.start-discovering-podcasts-consent-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 100; }

.start-discovering-podcasts-consent-overlay-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.start-discovering-consent-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 0;
  max-width: 200px;
  min-width: 200px;
  padding: 0.5rem 1.2rem;
  border: 3px solid white;
  border-radius: 40px;
  background: black;
  color: white;
  font-weight: bold;
  font-size: 0.9rem;
  text-align: left;
  animation: buttonAppearence 0.8s ease-in-out 1;
  animation-delay: 1.5s;
  animation-fill-mode: forwards;
  transform: translate(0%, 0%); }
  .start-discovering-consent-button:hover, .start-discovering-consent-button:focus {
    cursor: pointer;
    outline: none;
    background-color: #222; }

@keyframes buttonAppearence {
  0% {
    opacity: 0;
    transform: translate(0%, -20%); }
  100% {
    opacity: 1;
    transform: translate(0%, 0%); } }

.start-discovering-consent-button--icon {
  width: 26px;
  height: 26px;
  margin-right: 1rem; }
  .start-discovering-consent-button--icon svg {
    width: 100%;
    height: 26px; }

.start-discovering-consent-button--text {
  display: inline-block;
  max-width: 110px; }
