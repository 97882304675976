.show-info--wrapper {
  overflow: hidden;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.show-info--inner {
  position: relative;
  width: 100%;
  height: 100%;
  background: #262a2e;
  transform: translateY(100%); }

.show-info--inner-content {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%; }

.show-info--content {
  flex: 1 1 auto;
  overflow: auto;
  padding: 0 1.5rem 0 1.5rem; }
  .show-info--content p {
    padding: 0.25rem 0 1rem; }

.show-info--content--discover-podcasts-end {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 95%;
  max-width: 500px;
  text-align: center;
  font-weight: bold;
  transform: translate(-50%, -50%); }
  .show-info--content--discover-podcasts-end > div {
    padding: 0 2rem; }
  .show-info--content--discover-podcasts-end a {
    margin: 0 auto; }

.discover-podcasts-end--action-item {
  display: inline-block;
  max-width: 220px;
  margin: 2rem auto 1rem; }
