.linkbutton-01-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 210px;
  max-width: 210px;
  padding: 0.3rem 1rem;
  background: none;
  border: 3px solid white;
  border-radius: 30px;
  color: white;
  font-size: 0.85rem;
  font-weight: bold;
  font-style: normal;
  line-height: 1.2rem; }
  .linkbutton-01-wrapper:hover {
    opacity: 0.7;
    color: white;
    cursor: pointer; }

.linkbutton-01-wrapper_icon-only {
  composes: linkbutton-01-wrapper;
  border: none;
  padding: 0;
  min-width: auto; }

.linkbutton-01-icon {
  flex: 0 1 auto;
  width: 28px;
  height: 30px;
  padding: 0;
  border: none; }
  .linkbutton-01-icon svg {
    display: block;
    width: 100%;
    height: 100%;
    min-width: 25px;
    min-height: 25px; }

.linkbutton-01-icon__only {
  composes: linkbutton-01-icon;
  width: 45px;
  height: 45px;
  margin-right: 0; }

.linkbutton-01-label {
  display: inline-block;
  flex: 0 1 auto;
  max-width: 150px;
  text-align: left;
  padding-left: 1rem; }
  .linkbutton-01-label:only-child {
    padding-left: 0.5rem;
    max-width: 150px; }
