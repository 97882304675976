.clip-splash-item--indicator-wrapper {
  display: flex;
  margin-top: 0.5rem; }
  @media (min-height: 600px) {
    .clip-splash-item--indicator-wrapper {
      margin-top: 1rem; } }

.clip-splash-item--indicator-item {
  overflow: hidden;
  position: relative;
  flex: 0 1 auto;
  width: 100%;
  margin-right: 0.5rem;
  background: #999;
  height: 4px;
  border-radius: 2px; }
  .clip-splash-item--indicator-item:last-child {
    margin-right: 0; }

.clip-splash-item--indicator-item_active {
  composes: clip-splash-item--indicator-item; }

.clip-splash-item--indicator-item_selected-animated {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  transform: translateX(0%);
  transition: transform 1s cubic-bezier(0.2, 1, 0.05, 1); }
