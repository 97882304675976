.clip-splash--item--actions {
  padding: 2rem 1.5rem 2rem;
  text-align: center;
  color: #999999;
  font-style: italic; }

.clip-splash--item--actions-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; }

.clip-splash--item--actions-item {
  flex: 0 1 auto;
  padding: 0 0.5rem; }
  .clip-splash--item--actions-item:first-child {
    padding-left: 0; }
  .clip-splash--item--actions-item:last-child {
    padding-right: 0; }
