.show-info--header {
  flex: 0 1 auto;
  padding: 1.5rem 1.5rem 0 1.5rem; }

.show-info--header-inner {
  display: flex; }

.show-info--header-close-button {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 3em;
  line-height: 2rem;
  border: none;
  background: none;
  color: white;
  padding: 1.5rem 0.5rem 0.5rem 1.5rem; }
  .show-info--header-close-button:hover, .show-info--header-close-button:focus {
    cursor: pointer;
    color: #aaa; }

.show-info--header-label {
  font-size: 0.9rem;
  padding-left: 3rem; }
  .show-info--header-label div:first-child {
    font-weight: bold;
    padding-bottom: 0.2rem; }
  .show-info--header-label div:last-child {
    color: #aaa; }

.show-info--tabs {
  flex: 0 1 auto;
  padding: 1.5rem 1.5rem 1.5rem 1.5rem; }
  .show-info--tabs > ul {
    display: flex;
    background: #393c42;
    padding: 2px;
    border-radius: 10px; }
    .show-info--tabs > ul > li {
      flex: 0 1 auto;
      width: 100%; }
      .show-info--tabs > ul > li:first-child {
        border-radius: 10px 0 0 10px; }
      .show-info--tabs > ul > li:last-child {
        border-radius: 0 10px 10px 0; }

.show-info--tabs-button {
  padding: 0.25rem 0.5rem;
  line-height: 1.2rem;
  text-align: center;
  width: 100%;
  background: none;
  border: none;
  color: white;
  border-radius: 10px;
  transition: background-color 0.15s ease-in-out; }
  .show-info--tabs-button:hover, .show-info--tabs-button:focus {
    background: rgba(99, 99, 102, 0.4);
    cursor: pointer;
    outline: none; }

.show-info--tabs-button_active {
  composes: show-info--tabs-button;
  background: #636366; }
  .show-info--tabs-button_active:hover, .show-info--tabs-button_active:focus {
    background: #636366; }

.show-info--wrapper {
  overflow: hidden;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.show-info--inner {
  position: relative;
  width: 100%;
  height: 100%;
  background: #262a2e;
  transform: translateY(100%); }

.show-info--inner-content {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%; }

.show-info--content {
  flex: 1 1 auto;
  overflow: auto;
  padding: 0 1.5rem 0 1.5rem; }
  .show-info--content p {
    padding: 0.25rem 0 1rem; }

.show-info--content--content {
  padding-bottom: 10px;
  text-align: center; }
  .show-info--content--content p {
    text-align: left;
    padding-bottom: 2rem; }

.show-info--content--date {
  color: #bbb; }

.show-info--content--extra-areena {
  text-align: center;
  max-width: 200px;
  padding-bottom: 9rem;
  max-width: 400px;
  margin: 0 auto; }

.show-info--content--extra-areena-opinion {
  margin-top: 3rem; }

.show-info--content--extra-areena--action-item {
  display: inline-block; }

.show-info--button {
  display: inline-block;
  margin: 1rem 0 2rem;
  padding: 0.5rem 2rem;
  max-width: 180px;
  background: none;
  color: white;
  font-size: 0.8em;
  font-weight: bold;
  border: 2px solid white;
  border-radius: 30px; }
  .show-info--button:hover, .show-info--button:focus {
    cursor: pointer; }
