.clip-splash-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  transition: filter 0.2s ease-in-out; }

.clip-splash-items_inactive {
  composes: clip-splash-items;
  filter: blur(5px); }

.clip-splash--item {
  flex: 0 1 auto; }

.clip-splash--item--content {
  composes: clip-splash--item;
  position: relative;
  flex: 1 1 auto;
  max-height: 500px; }
  @media (min-width: 400px) {
    .clip-splash--item--content {
      max-height: 500px; } }
  @media (min-width: 700px) {
    .clip-splash--item--content {
      max-height: 480px; } }
  @media (min-width: 900px) {
    .clip-splash--item--content {
      max-height: 620px; } }

.clip-splash--item--content-inner {
  position: relative;
  width: 100%;
  height: 100%; }

.clip-splash--item--pagination {
  composes: clip-splash--item;
  padding: 1rem 1.5rem 0.7rem; }
  @media (min-width: 620px) {
    .clip-splash--item--pagination {
      padding: 1.5rem 1.5rem 1.1rem; } }
