.show-info--wrapper {
  overflow: hidden;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.show-info--inner {
  position: relative;
  width: 100%;
  height: 100%;
  background: #262a2e;
  animation: showInfoAppearence 0.7s cubic-bezier(0.2, 1, 0.05, 1) 1; }

@keyframes showInfoAppearence {
  0% {
    transform: translateY(100%); }
  100% {
    transform: translateY(0%); } }

.show-info--inner-content {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%; }

.show-info--content {
  flex: 1 1 auto;
  overflow: auto;
  padding: 0 1.5rem 0 1.5rem; }
  .show-info--content p {
    padding: 0.25rem 0 1rem; }

.show-info--content--before-continue {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 95%;
  max-width: 500px;
  text-align: center;
  font-weight: bold;
  transform: translate(-50%, -50%); }
  .show-info--content--before-continue > div {
    text-align: center;
    padding: 0 2rem; }
  .show-info--content--before-continue a {
    margin: 0 auto; }

.before-continue--item {
  margin: 2rem 0 3rem; }
  .before-continue--item > span {
    line-height: 2rem; }
